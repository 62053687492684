import React from 'react';
import styled from 'styled-components';

const FeaturesSection = styled.section`
  padding: 50px 20px;
`;

const FeatureGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
`;

const FeatureItem = styled.div`
  background-color: ${({ theme }) => theme.colors.secondary};
  padding: 20px;
  border-radius: 10px;
  text-align: center;

  & > h3 {
    margin-bottom: 10px;
    color: ${({ theme }) => theme.colors.primary};
  }
`;

function Features() {
  return (
    <FeaturesSection id="features">
      <h2>Features</h2>
      <FeatureGrid>
        <FeatureItem>
          <h3>🎮 Streamer Tools</h3>
          <p>Boost your streams with our mini-games and interactive overlays.</p>
        </FeatureItem>
        <FeatureItem>
          <h3>🕹️ Game Servers</h3>
          <p>Easily build game servers for your community effortlessly.</p>
        </FeatureItem>
        <FeatureItem>
          <h3>📅 Manage Events</h3>
          <p>Coordinate, schedule, and run events seamlessly.</p>
        </FeatureItem>
        <FeatureItem>
          <h3>🤝 Collaborate</h3>
          <p>Plan joint streams, charity events, or just a friendly competition.</p>
        </FeatureItem>
      </FeatureGrid>
    </FeaturesSection>
  );
}

export default Features;
