import React from 'react';
import styled from 'styled-components';

const HeaderContainer = styled.header`
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.secondary};
`;

const Logo = styled.h1`
  font-size: 24px;
  color: ${({ theme }) => theme.colors.primary};
`;

const Nav = styled.nav`
  & > a {
    margin-left: 20px;
    color: ${({ theme }) => theme.colors.text};
  }
`;

function Header() {
  return (
    <HeaderContainer>
      <Logo>Goatying</Logo>
      <Nav>
        <a href="#features">Features</a>
        <a href="#pricing">Pricing</a>
        <a href="#contact">Contact</a>
      </Nav>
    </HeaderContainer>
  );
}

export default Header;
