import React from 'react';
import styled from 'styled-components';

const FAQSection = styled.section`
  padding: 50px 20px;
  background-color: ${({ theme }) => theme.colors.secondary};
`;

const FAQItem = styled.div`
  margin-bottom: 20px;

  & > h3 {
    color: ${({ theme }) => theme.colors.primary};
  }

  & > p {
    color: ${({ theme }) => theme.colors.text};
  }
`;

function FAQ() {
  return (
    <FAQSection id="faq">
      <h2>FAQs</h2>
      <FAQItem>
        <h3>How does the game server hosting work?</h3>
        <p>Our platform provides easy setup and management tools for popular game servers.</p>
      </FAQItem>
      <FAQItem>
        <h3>Can I integrate Goatying with my existing streaming software?</h3>
        <p>Yes, our tools are designed to seamlessly integrate with most streaming setups.</p>
      </FAQItem>
    </FAQSection>
  );
}

export default FAQ;
