// App.js

import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import GlobalStyles from './GlobalStyles'; // Your global styles
import Header from './components/Header';
import Hero from './components/Hero';
import Features from './components/Features';
import Testimonials from './components/Testimonials';
import Pricing from './components/Pricing';
import FAQ from './components/FAQ';
import Footer from './components/Footer';

const theme = {
  colors: {
    background: '#121212',
    primary: '#30d0a0',
    secondary: '#535353',
    text: '#FFFFFF',
    accent: '#330867',
  },
  fonts: {
    main: 'Arial, sans-serif',
  },
};

const AppContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.text};
  font-family: ${({ theme }) => theme.fonts.main};
  min-height: 100vh;
`;

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AppContainer>
        <GlobalStyles />
        <Header />
        <Hero />
        <Features />
        <Testimonials />
        <Pricing />
        <FAQ />
        <Footer />
      </AppContainer>
    </ThemeProvider>
  );
}

export default App;
