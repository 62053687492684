import React from 'react';
import styled from 'styled-components';

const HeroSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 100px 20px;
  background: rgb(48,208,160);
  background: linear-gradient(90deg, rgba(48,208,160,1) 0%, rgba(51,8,103,1) 35%, rgba(0,0,0,1) 100%);
  text-align: center;
`;

const Tagline = styled.h1`
  color: transparent;
  background-color: #666666;
  background-clip: text;
  text-shadow: rgba(255, 255, 255, 1) 0px 2px 1px;
  border-radius: 8px;
`;

const Subtext = styled.h3`
  margin-top: 20px;
  color: transparent;
  background-color: #666666;
  background-clip: text;
  text-shadow: rgba(255, 255, 255, 1) 0px 2px 1px;
  border-radius: 8px;
`;

const CTAButton = styled.a`
  margin-top: 40px;
  padding: 10px 20px;
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.text};
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    background-color: #fff;
    color: ${({ theme }) => theme.colors.primary};
    text-decoration: none;
  }
`;

function Hero() {
  return (
    <HeroSection>
      <Tagline>Unleash the Power of Streaming with Goatying</Tagline>
      <Subtext>The ultimate platform for streamers to engage, entertain, and grow their communities. Let's Goaty!</Subtext>
      <CTAButton href="#get-started">Get Started Now</CTAButton>
    </HeroSection>
  );
}

export default Hero;
