import React from 'react';
import styled from 'styled-components';

const TestimonialsSection = styled.section`
  padding: 50px 20px;
  background-color: ${({ theme }) => theme.colors.secondary};
`;

const TestimonialItem = styled.div`
  margin-bottom: 20px;

  & > p {
    color: ${({ theme }) => theme.colors.text};
    font-style: italic;
  }

  & > h4 {
    margin-top: 10px;
    color: ${({ theme }) => theme.colors.primary};
  }
`;

function Testimonials() {
  return (
    <TestimonialsSection>
      <h2>Hear from the Goatying Community</h2>
      <TestimonialItem>
        <p>"Goatying transformed how I engage with my audience. The mini-games are a hit!"</p>
        <h4>- StreamerName</h4>
      </TestimonialItem>
      <TestimonialItem>
        <p>"Setting up game servers has never been easier. My community loves it!"</p>
        <h4>- AnotherStreamer</h4>
      </TestimonialItem>
    </TestimonialsSection>
  );
}

export default Testimonials;
