import React from 'react';
import styled from 'styled-components';

const PricingSection = styled.section`
  padding: 50px 20px;
`;

const PricingGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
`;

const PricingCard = styled.div`
  background-color: ${({ theme }) => theme.colors.secondary};
  padding: 20px;
  border-radius: 10px;
  text-align: center;

  & > h3 {
    margin-bottom: 10px;
    color: ${({ theme }) => theme.colors.primary};
  }
`;

function Pricing() {
  return (
    /*<PricingSection id="pricing">
      <h2>Transparent and Flexible Pricing</h2>
      <PricingGrid>
        <PricingCard>
          <h3>Basic</h3>
          <p>$9.99/month</p>
          <p>Includes basic tools and features.</p>
        </PricingCard>
        <PricingCard>
          <h3>Pro</h3>
          <p>$19.99/month</p>
          <p>All basic features plus advanced tools.</p>
        </PricingCard>
        <PricingCard>
          <h3>Premium</h3>
          <p>$29.99/month</p>
          <p>Includes all features with priority support.</p>
        </PricingCard>
      </PricingGrid>
    </PricingSection>*/""
  );
}

export default Pricing;
