import React from 'react';
import styled from 'styled-components';

const FooterContainer = styled.footer`
  padding: 20px;
  background-color: ${({ theme }) => theme.colors.secondary};
  text-align: center;

  & > p {
    color: ${({ theme }) => theme.colors.text};
  }

  & > a {
    color: ${({ theme }) => theme.colors.accent};
  }
`;

function Footer() {
  return (
    <FooterContainer id="contact">
      <p>Copyright © 2024 Goaty.ing. All rights reserved.</p>
      <p>
        <a href="#privacy">Privacy Policy</a> | <a href="#terms">Terms of Service</a>
      </p>
      <p>
        <a href="mailto:contact@goatying.com">Contact Us</a>
      </p>
    </FooterContainer>
  );
}

export default Footer;
